import { useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react';
import heic2any from 'heic2any';
import Uppy, { debugLogger } from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import Webcam from '@uppy/webcam';
import XHRUpload from '@uppy/xhr-upload';
import ImageEditor from '@uppy/image-editor';
import DropTarget from '@uppy/drop-target';
import Compressor from '@uppy/compressor';
import PropTypes from 'prop-types';
import Enumerable from 'linq';
import Resizer from 'react-image-file-resizer';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/screen-capture/dist/style.css';
import '@uppy/image-editor/dist/style.css';
import { Label, Row } from 'reactstrap';

import * as fileHelper from '../../functions/my-file';
import { getAsync, deleteAsync, generateAccessToken } from '../../functions/my-api';
import { ErrorModal } from '../../views/modals/CustomModals';
import { getElementSize, isImage } from '../../functions/common';

const UppyFileUploader = forwardRef((props, ref) => {
  const XHR_ENDPOINT = `${props.uploadUrl}?photoFor=${props.id}&description=${props.description}`;

  const [actions, setActions] = useState([]);
  const [existingFiles, setExistingFiles] = useState(props.existingFiles ?? []);
  const [loadedFiles, setLoadedFiles] = useState(0);
  const [loading, setLoading] = useState(false);

  const errorModalRef = useRef();

  useImperativeHandle(ref, () => ({
    getExistingFiles() {
      return existingFiles;
    },
    isEmpty() {
      return loadedFiles === 0;
    },
  }));

  const handleUppyFileDownload = async (id) => {
    const response = await getAsync(`${props.downloadUrl}/${id}`);

    let base64String = '';
    if (response.data.Base64String) {
      base64String = response.data.Base64String;
    } else {
      const binaryString = Array.from(response.data.FileContent.data)
        .map((byte) => String.fromCharCode(byte))
        .join('');
      base64String = window.btoa(binaryString);
    }

    fileHelper.DownloadFile({
      base64String,
      fileName: response.data.FileName,
      mimeType: response.data.MimeType,
    });
  };

  const getContainerClass = (id) => {
    const width = getElementSize(id);
    switch (width) {
      case 'small': {
        return 'col-sm-5';
      }
      case 'medium': {
        return 'col-md-3';
      }
      case 'large': {
        return 'col-lg-2';
      }
      default: {
        return 'col-sm-5';
      }
    }
  };

  const removeFile = async (uppyDashboard, file, deleteServerCopy) => {
    // Now remove the file using the fileId
    if (!file?.meta?.existingFile || deleteServerCopy) {
      uppyDashboard.removeFile(file.id);
    }

    if (file?.meta?.serverId && deleteServerCopy) {
      setLoading(true);

      const response = await deleteAsync(`${props.downloadUrl}/${file.meta.serverId}`);
      if (response.status === 200) {
        if (props.fileChangedCallback) {
          props.fileChangedCallback(file, 'file-removed');
        }

        setLoadedFiles(uppyDashboard.getFiles().length);
        /* eslint-disable no-use-before-define */
        generateFileActions(uppyDashboard);

        setLoading(false);
      } else {
        setLoading(false);
        errorModalRef.current.toggleModal();
      }
    }
  };

  const generateFileActions = (uppyDashboard) => {
    const rows = [];
    const thisClass = getContainerClass(`${props.id}_loading`);

    uppyDashboard.getFiles().forEach((file) => {
      rows.push(
        <Row id={file.id} key={`${file.id}`}>
          <div
            className={thisClass}
            style={{ marginLeft: '10px', textAlign: 'left', display: 'flex', alignItems: 'center' }}
          >
            <Label
              style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
              className="mt-2"
            >
              {file.name}
            </Label>
          </div>
          <div className={thisClass} style={{ textAlign: 'left' }}>
            <Label
              className="mt-2 text-primary"
              style={{ paddingRight: '20px', cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => {
                handleUppyFileDownload(file.meta.serverId);
              }}
            >
              Download
            </Label>
            {!props.disabled ? (
              <Label
                className="mt-2 text-danger"
                style={{
                  paddingRight: '20px',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                onClick={() => {
                  removeFile(uppyDashboard, file, true);
                }}
              >
                Remove
              </Label>
            ) : (
              ''
            )}
          </div>
        </Row>,
      );
    });

    setActions(rows);
  };

  const loadExistingFilesToUppy = (uppyInstance) => {
    existingFiles.forEach((element) => {
      uppyInstance.addFile({
        name: element.FileName,
        type: element.MimeType,
        data: {},
        source: 'URL', // optional, determines the source of the file, for example, Instagram.
        isRemote: true, // optional, determines whether the file is remote or not.
        meta: {
          serverId: element.ID,
          existingFile: true,
        },
      });
    });

    setExistingFiles([]);
    generateFileActions(uppyInstance);
  };

  const processFile = async (uppyDashboard, file) => {
    const tempFile = {
      name: file.name,
      type: file.type,
      data: file.data, // Use file.data directly
    };

    if (!file?.meta?.checked && isImage(file.name, file.type)) {
      // uppyDashboard.removeFile(`${file.id}_no_server_delete`);
      removeFile(uppyDashboard, file, false);
    }

    if (file.type === 'image/heic' || file.type === 'image/heif') {
      // Convert the file from HEIC/HEIF to JPEG
      heic2any({
        blob: file.data,
        toType: 'image/jpeg',
        quality: 0.8,
      })
        .then((conversionResult) => {
          // Extract the file name without extension
          const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, '');
          // Create a new file object from the conversion result
          const newFile = new File([conversionResult], `${fileNameWithoutExtension}.jpeg`, {
            type: 'image/jpeg',
            lastModified: Date.now(),
          });
          // Add the new file to Uppy
          uppyDashboard.addFile({
            name: newFile.name,
            type: newFile.type,
            data: newFile,
            source: 'Local', // optional, determines the source of the file, for example, Instagram.
            isRemote: false, // optional, determines whether the file is remote or not.
          });
        })
        .catch((error) => {
          console.error('Error converting file:', error);
        });
    }

    if (file.data.size > 0 && !file?.meta?.checked) {
      return new Promise((resolve) => {
        const image = new Image();
        image.src = URL.createObjectURL(tempFile.data);

        image.onload = () => {
          const maxWidth = 1700;
          const maxHeight = 1900;

          let margin = 1;
          let newWidth = image.width;
          let newHeight = image.height;

          if (
            image.width < maxWidth &&
            image.height < maxHeight &&
            image.width < maxHeight &&
            image.height < maxWidth
          ) {
            uppyDashboard.addFile({
              name: tempFile.name,
              type: tempFile.type,
              data: tempFile.data,
              meta: { checked: true }, // Add a meta property to flag the file
            });
            resolve();
            return;
          }

          while (
            !(
              newWidth < maxWidth &&
              newHeight < maxHeight &&
              newWidth < maxHeight &&
              newHeight < maxWidth
            )
          ) {
            margin -= 0.05; // decrease the page by 5% everytime it does not fit the maxWidth and maxHeight
            const targetWidth = maxWidth * margin;
            const targetHeight = maxHeight * margin;
            const aspectRatio = newWidth / newHeight;

            if (aspectRatio > 1) {
              newWidth = targetWidth;
              newHeight = targetWidth / aspectRatio;
              if (newHeight > targetHeight) {
                newHeight = targetHeight;
                newWidth = targetHeight * aspectRatio;
              }
            } else {
              newHeight = targetHeight;
              newWidth = targetHeight * aspectRatio;
              if (newWidth > targetWidth) {
                newWidth = targetWidth;
                newHeight = targetWidth / aspectRatio;
              }
            }
          }

          Resizer.imageFileResizer(
            tempFile.data,
            newWidth,
            newHeight,
            'JPEG',
            100,
            0,
            (resizedBlob) => {
              const resizedFile = new File([resizedBlob], tempFile.name, {
                type: 'image/jpeg',
                lastModified: Date.now(),
              });

              resizedFile.data = resizedFile;
              processFile(uppyDashboard, resizedFile, margin);

              resolve();
            },
            'blob',
          );
        };
      });
    }
    // If no processing needed, resolve immediately
    return Promise.resolve();
  };

  const createUppy = async () => {
    const token = await generateAccessToken();

    const uppyExists = document.getElementById(props.id)?.childNodes.length;

    if (!uppyExists && token) {
      const uppyDashboard = new Uppy({
        logger: debugLogger,
        onBeforeFileAdded: () => true,
        autoProceed: true, // Automatically start uploading files
        individualCancellation: true, // Enable individual file cancellation
      })
        .use(Dashboard, {
          inline: true,
          target: `#${props.id}`,
          showProgressDetails: true,
          proudlyDisplayPoweredByUppy: true,
          width: props.width ?? '100%',
          height: props.height ?? '300px',
          singleFileFullScreen: true,
          disabled: props.disabled ?? false,
        })
        .use(Webcam, {
          target: Dashboard,
          showVideoSourceDropdown: false,
          showRecordingLength: false,
          modes: ['picture'],
        })
        .use(ImageEditor, { target: Dashboard })
        .use(DropTarget, {
          target: document.body,
        })
        .use(Compressor)
        .use(XHRUpload, {
          endpoint: XHR_ENDPOINT,
          bundle: true,
          formData: true,
          fieldName: 'my_files',
          headers: {
            // Add your Authorization header here
            Authorization: `Bearer ${token}`,
          },
        });

      window.uppy = uppyDashboard;

      uppyDashboard.on('complete', (result) => {
        if (
          result.successful.length === 0 &&
          result.failed.length === 0 &&
          existingFiles.length > 0
        ) {
          // this means the file added was an existing file.
          setLoading(false);
          setLoadedFiles(existingFiles.length);
          return;
        }

        if (result.successful.length > 0) {
          setLoadedFiles(result.successful.length);

          setLoading(false);

          const filesWithNoServerIds = Enumerable.from(uppyDashboard.getFiles())
            .where((x) => x.meta.serverId === undefined)
            .toArray();

          filesWithNoServerIds.forEach((file) => {
            const first = Enumerable.from(result.successful[0].response.body).firstOrDefault(
              (x) => x.FileName === file.name,
            );

            console.log(`ID: ${first?.ID}`);

            file.meta.serverId = first?.ID;
          });

          generateFileActions(uppyDashboard);

          if (props.uploadCompleteCallback) {
            result.uppyGroupId = props.id;
            props.uploadCompleteCallback(result);
          }

          if (props.fileChangedCallback) {
            props.fileChangedCallback(result, 'complete');
          }
        }
      });

      uppyDashboard.on('files-added', (files) => {
        setLoading(true);

        Promise.all(files.map((file) => processFile(uppyDashboard, file)))
          .then(() => {
            console.log('All files processed');
          })
          .catch((error) => {
            console.error('Error processing files:', error);
          });
      });

      uppyDashboard.on('error', (error) => {
        console.error('Error with Uppy:', error);
      });

      loadExistingFilesToUppy(uppyDashboard);

      uppyDashboard.getFiles().forEach((file) => {
        uppyDashboard.setFileState(file.id, {
          uploadURL: XHR_ENDPOINT,
          progress: { uploadComplete: true, uploadStarted: true, percentage: 100 },
        });
      });
    }
  };

  useEffect(() => {
    createUppy();
  }, []);

  return (
    <>
      <div
        style={{ backgroundColor: 'white', display: loading ? 'none' : 'block' }}
        className="bg-white"
      >
        <div
          id={props.id}
          name={props.name}
          className="bg-white"
          style={{ backgroundColor: 'white', display: 'block' }}
        >
          {actions}
        </div>
      </div>
      <div
        id={`${props.id}_loading`}
        className="bg-white"
        style={{
          backgroundColor: 'white',
          display: loading ? 'block' : 'none',
          textAlign: 'center',
        }}
      >
        <h2>Generating Preview. Please wait.</h2>
      </div>
      <ErrorModal ref={errorModalRef} />
    </>
  );
});

UppyFileUploader.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  uploadUrl: PropTypes.string,
  existingFiles: PropTypes.array,
  downloadUrl: PropTypes.string,
  description: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  uploadCompleteCallback: PropTypes.func,
  disabled: PropTypes.bool,
  fileChangedCallback: PropTypes.func,
};

export default UppyFileUploader;
