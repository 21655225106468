import React, { useState, useCallback, useImperativeHandle, forwardRef } from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import PropTypes from 'prop-types';
import _ from 'lodash'; // Import Lodash
import { getAsync } from '../../functions/my-api';

const ContractorAutoSuggest = forwardRef((props, ref) => {
  const [inputValue, setInputValue] = useState(props.initialValue ?? '');
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [suggestions, setSuggestions] = useState([]); // Suggestions from API call

  const CONTRACTORS_URL = `${process.env.REACT_APP_BASEAPI_URL}/simpro/contractors`;

  const filteredSuggestions = suggestions.filter((suggestion) =>
    suggestion.Name.toLowerCase().includes(inputValue.toLowerCase()),
  );

  // Debounced API call
  const fetchSuggestions = useCallback(
    _.debounce(async (value) => {
      const url = `${CONTRACTORS_URL}?search=${value}`;
      const response = await getAsync(url);
      setSuggestions(response.data);
      setDropdownVisible(true);
    }, 1000),
    [],
  ); // Only re-create if dependencies change

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    fetchSuggestions(e.target.value);
  };

  useImperativeHandle(ref, () => ({
    clear() {
      setInputValue('');
    },
  }));

  return (
    <div style={{ position: 'relative' }}>
      <input
        className="form-control"
        type="text"
        placeholder="Select a contractor from Simpro"
        value={inputValue}
        disabled={props.disabled}
        onChange={handleInputChange}
        onBlur={() => {
          setTimeout(() => setDropdownVisible(false), 150); // Hide dropdown with a slight delay to allow for selection
        }}
      />

      {isDropdownVisible && (
        <ListGroup style={{ position: 'absolute', top: '100%', width: '100%', zIndex: 1 }}>
          {filteredSuggestions.map((suggestion, index) => (
            <ListGroupItem
              style={{
                backgroundColor: index === hoveredIndex ? '#0d6efd' : 'white',
                color: index === hoveredIndex ? 'white' : '#8B969C',
              }}
              tag="button"
              action
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              //eslint-disable-next-line react/no-array-index-key
              key={index}
              onMouseDown={() => {
                setInputValue(`${suggestion.Name} - ${suggestion.ContactName}`);

                if (props.changeCallBack) {
                  props.changeCallBack(`${suggestion.Name} - ${suggestion.ContactName}`);
                }
              }} // Use onMouseDown to capture selection before onBlur
            >
              {`${suggestion.Name} - ${suggestion.ContactName}`}
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </div>
  );
});

ContractorAutoSuggest.propTypes = {
  changeCallBack: PropTypes.func,
  initialValue: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ContractorAutoSuggest;
