/* eslint-disable react/no-array-index-key */
import { useImperativeHandle, useState, forwardRef, useRef } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Field, useFormik } from 'formik';
import PropTypes from 'prop-types';

const ProcessingModal = forwardRef((props, ref) => {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  const isModalOpen = () => {
    return modal;
  };

  useImperativeHandle(ref, () => ({
    toggleModal,
    isModalOpen,
  }));

  return (
    <Modal isOpen={modal} backdrop="static">
      <ModalHeader>Processing...</ModalHeader>
      <ModalBody className="text-center">
        Please Wait. Popup will close automatically <i className="me-2 bi-emoji-smile"></i>
      </ModalBody>
      <ModalFooter />
    </Modal>
  );
});

const SuccessModal = forwardRef((props, ref) => {
  const [modal, setModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const toggleModal = (message) => {
    setModal(!modal);

    if (typeof message === 'string') {
      setSuccessMessage(message);
    } else {
      setSuccessMessage(null);
    }
  };

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  return (
    <Modal isOpen={modal} toggle={toggleModal.bind(null)}>
      <ModalHeader toggle={toggleModal.bind(null)}>SUCCESS</ModalHeader>
      <ModalBody className="text-center">
        {successMessage ?? 'The form has been successfully saved! '}{' '}
        <i className="me-2 bi-emoji-smile"></i>
      </ModalBody>
      <ModalFooter />
    </Modal>
  );
});

const ErrorModal = forwardRef((props, ref) => {
  const [modal, setModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const toggleModal = (error) => {
    setModal(!modal);

    if (typeof error === 'string') {
      setErrorMessage(error);
    } else {
      setErrorMessage(null);
    }
  };

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  return (
    <Modal isOpen={modal} toggle={toggleModal.bind(null)}>
      <ModalHeader toggle={toggleModal.bind(null)}>ERROR</ModalHeader>
      <ModalBody className="text-center">
        {errorMessage ?? 'The system has encountered an error! '}{' '}
        <i className="me-2 bi-emoji-frown"></i>
      </ModalBody>
      <ModalFooter />
    </Modal>
  );
});

const ConfirmationModal = forwardRef((props, ref) => {
  const formik = useFormik({
    initialValues: {
      commentBody: '',
    },
  });

  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  const getCommentBody = () => {
    const comment = formik.values.commentBody;
    return comment;
  };

  useImperativeHandle(ref, () => ({
    toggleModal,
    getCommentBody,
  }));

  return (
    <Modal isOpen={modal} backdrop="static">
      <ModalHeader>Please Confirm</ModalHeader>
      <ModalBody className="text-left">
        {props.body} <i className="me-2 bi-emoji-smile"></i>
        {props.showCommentBox ? (
          <Field
            className="form-control mt-3"
            component="textarea"
            name="commentBody"
            placeholder="PLEASE ENTER YOUR COMMENTS HERE"
            defaultValue={props?.commentBoxValue}
            rows="3"
            style={{ resize: 'none' }}
            onChange={formik.handleChange}
          />
        ) : (
          ''
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            if (props.submitCallBack) {
              props.submitCallBack('Submitted', props.formikProps, true);
            }

            toggleModal();
          }}
        >
          {props.confirmText ? props.confirmText : 'Save'}
        </Button>
        <Button
          color="primary"
          onClick={() => {
            if (props.cancelCallBack) {
              props.cancelCallBack();
            }

            toggleModal();
          }}
        >
          {props.cancelText ? props.cancelText : 'Cancel'}
        </Button>
      </ModalFooter>
    </Modal>
  );
});

const OtpConfirmationModal = forwardRef((props, ref) => {
  const [modal, setModal] = useState(false);
  const otpInputRef = useRef(null);

  const toggleModal = () => {
    setModal(!modal);
  };

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  return (
    <Modal isOpen={modal} toggle={toggleModal.bind(null)} backdrop="static">
      <ModalHeader toggle={toggleModal.bind(null)}>One-time Password Verification</ModalHeader>
      <ModalBody className="text-left ms-5 me-5">
        <input type="text" ref={otpInputRef} className="form-control"></input>
      </ModalBody>
      <ModalFooter>
        <div className="text-muted text-left w-100">
          Have not received OTP for more than 72 hours? &nbsp;
          <span
            className="text-link"
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={props.resendOtpCallback}
          >
            Resend OTP
          </span>
        </div>
        {/* <a href={props.resendOtpCallback}>Resend OTP</a> */}

        <Button
          style={{ width: '100%' }}
          color="primary"
          onClick={() => {
            props.verifyOtpCallback(otpInputRef.current.value);
          }}
        >
          VERIFY & PROCEED
        </Button>
      </ModalFooter>
    </Modal>
  );
});

const VeryLargeModal = forwardRef((props, ref) => {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  return (
    <Modal size="xl" isOpen={modal} toggle={toggleModal.bind(null)} backdrop="static">
      <ModalHeader toggle={toggleModal.bind(null)}>{props.header ?? 'Please confirm'}</ModalHeader>
      <ModalBody id={props?.id} className="text-center">
        {props.children}
      </ModalBody>
      {props.showFooterButtons ? (
        <ModalFooter className="d-block">
          <input
            type="button"
            style={{ width: '100px' }}
            className="btn btn btn-danger btn-sm"
            value="Cancel"
            onClick={toggleModal.bind(null)}
          />
          {props.submitCallBack ? (
            <input
              type="button"
              style={{ width: '100px' }}
              className="btn btn btn-primary btn-sm"
              value="Save"
              onClick={() => {
                props.submitCallBack('Submitted', props.formikProps, true);
              }}
            />
          ) : (
            ''
          )}
          {props.draftSubmitCallBack ? (
            <input
              type="button"
              style={{ width: '100px' }}
              className="btn btn btn-primary btn-sm"
              value="Save as Draft"
              onClick={() => {
                props.draftSubmitCallBack('Submitted', props.formikProps, true);
              }}
            />
          ) : (
            ''
          )}
        </ModalFooter>
      ) : (
        ''
      )}
    </Modal>
  );
});

const SmallModal = forwardRef((props, ref) => {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  return (
    <Modal isOpen={modal} toggle={toggleModal.bind(null)} backdrop="static">
      <ModalHeader toggle={toggleModal.bind(null)}>{props.header ?? 'Please confirm'}</ModalHeader>
      <ModalBody id={props?.id} className="text-center pb-0">
        {props.children}
      </ModalBody>
      {props.showSubmitButton || props.showCancelButton ? (
        <ModalFooter>
          {props.showSubmitButton ? (
            <Button
              color="primary"
              onClick={() => {
                props.submitCallBack('Submitted', props.formikProps, true);
                // toggleModal();
              }}
            >
              Save
            </Button>
          ) : (
            ''
          )}
          {props.showCancelButton ? (
            <Button color="primary" onClick={toggleModal.bind(null)}>
              Cancel
            </Button>
          ) : (
            ''
          )}
        </ModalFooter>
      ) : (
        ''
      )}
    </Modal>
  );
});

const WarningModal = forwardRef((props, ref) => {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  return (
    <Modal isOpen={modal} toggle={toggleModal.bind(null)}>
      <ModalHeader toggle={toggleModal.bind(null)}>WARNING</ModalHeader>
      <ModalBody className="text-center">
        {props.body} <i className="me-2 bi-emoji-smile"></i>
      </ModalBody>
      <ModalFooter />
    </Modal>
  );
});

const RequiredFieldsWarningModal = forwardRef((props, ref) => {
  const [modal, setModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  const toggleModal = (e) => {
    setModal(!modal);
    if (Array.isArray(e)) setValidationErrors(e);
  };

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  return (
    <Modal isOpen={modal} toggle={toggleModal.bind(null)}>
      <ModalHeader toggle={toggleModal.bind(null)}>WARNING</ModalHeader>
      <ModalBody className="text-left">
        <ul>
          {validationErrors?.map((message, index) => {
            // eslint-disable-next-line react/no-array-index-key
            return (
              <li className="text-danger" key={`${message}_${index}`}>
                <h5 className="text-danger">{message}</h5>
              </li>
            );
          })}
        </ul>
      </ModalBody>
      <ModalFooter />
    </Modal>
  );
});

const GenericMessageModal = forwardRef((props, ref) => {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  useImperativeHandle(ref, () => ({
    toggleModal,
  }));

  return (
    <Modal isOpen={modal} toggle={toggleModal.bind(null)}>
      <ModalHeader toggle={toggleModal.bind(null)}>{props.title}</ModalHeader>
      <ModalBody className="text-center">
        {props.body} <i className="me-2 bi-emoji-smile"></i>
      </ModalBody>
      <ModalFooter />
    </Modal>
  );
});

ConfirmationModal.propTypes = {
  body: PropTypes.string,
  formikProps: PropTypes.object,
  submitCallBack: PropTypes.func.isRequired,
  cancelCallBack: PropTypes.func,
  showCommentBox: PropTypes.bool,
  commentBoxValue: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
};

VeryLargeModal.propTypes = {
  id: PropTypes.string,
  children: PropTypes.object,
  formikProps: PropTypes.object,
  submitCallBack: PropTypes.func,
  draftSubmitCallBack: PropTypes.func,
  showFooterButtons: PropTypes.bool,
  header: PropTypes.string,
};

SmallModal.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  formikProps: PropTypes.object,
  submitCallBack: PropTypes.func,
  showSubmitButton: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  header: PropTypes.string,
};

WarningModal.propTypes = {
  body: PropTypes.string,
};

RequiredFieldsWarningModal.propTypes = {};

GenericMessageModal.propTypes = {
  body: PropTypes.string,
  title: PropTypes.string,
};

OtpConfirmationModal.propTypes = {
  resendOtpCallback: PropTypes.func,
  verifyOtpCallback: PropTypes.func,
};

export {
  ProcessingModal,
  SuccessModal,
  ErrorModal,
  ConfirmationModal,
  VeryLargeModal,
  WarningModal,
  SmallModal,
  GenericMessageModal,
  RequiredFieldsWarningModal,
  OtpConfirmationModal,
};
